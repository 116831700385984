import { render, staticRenderFns } from "./NewsTabs.vue?vue&type=template&id=2c4064b0&scoped=true&lang=true"
import script from "./NewsTabs.vue?vue&type=script&lang=js"
export * from "./NewsTabs.vue?vue&type=script&lang=js"
import style0 from "./NewsTabs.vue?vue&type=style&index=0&id=2c4064b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c4064b0",
  null
  
)

export default component.exports