<template>
    <div>
        <div class="container-fluid">
            <div class="row"> 
                        <div class="col-lg-7">
                            <div class="post-layout-top-content">
                                <div
                                  class="
                                    post-categories
                                    d-flex
                                    justify-content-between
                                    align-content-center
                                  "
                                >
                                  <div class="categories-item">
                                    <span>{{news.categories[0].name}}</span>
                                  </div> 
                                </div>
                                <div class="post-content">
                                  <h3 class="title">
                                  {{news.title}}
                                  </h3>
                                  <p>
                                  {{news.short_description}}
                                  </p>
                                  <div class="thumb">
                                    <img  :src="news.image" alt="" />
                                    <!-- <img  src="@/assets/images/post-layout.png" alt="" /> -->
                                  </div>
                                </div>
                             
                                <div class="post-text mt-30" v-html="news.description" >
                                 
                                </div>
                           
                              </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-12 mt-5 pb-5">
                            <RightBar :datas="Posts" :tabs="sideBarTabs" />
                        </div>
                    </div>
               
        </div>
    </div>
</template>

<script>
    import trendingPost from "@/components/Data/HomeTwoPost";
    import RightBar from "@/components/Partials/RightBar.vue";
    import Posts from "@/components/Data/NewsRowCard";

    export default {
        metaInfo () {
    return {
      title: this.$route.params.id,
        meta: [
            { vmid: 'description', name: 'description', content: 'News Details' },
            { vmid: 'keywords', name: 'keywords', content: 'News Details' },
            { vmid: 'og:title', property: 'og:title', content: 'News Details' },
            { vmid: 'og:description', property: 'og:description', content: 'News Details' },
            { vmid: 'twitter:title', name: 'twitter:title', content: 'News Details' },
            { vmid: 'twitter:description', name: 'twitter:description', content: 'News Details' },
        ]
    }
  },
        components: {
            RightBar,
        },
        
        data() {
            return {
                slug:this.$route.params.slug,
                news:null,
                sideBarTabs: [    {
        title: "Weekly Special",
        value: 1,
      },
      {
        title: "Sessional Bonus",
        value: 2,
      },
      {
        title: "Latest",
        value: 3,
      },
            ],
            Posts: Posts.data,
            selected: "latest",
            scrollTop: 0,
            sidebar: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
            };
        }, 
        mounted() {
            document.body.classList.add("home-3-bg");
            this.getNews();
        },
        beforeDestroy() {
            document.body.classList.remove("home-3-bg");

        },
        created() {
            document.addEventListener("scroll", this.topToBottom);
        },
        watch: {
            $route(to) {
                this.slug = to.params.id;
            this.getNews();

            },
            scrollTop(newValue, oldValue) {
                // Do something with the new value or perform additional actions
                console.log('scrollTop changed:', newValue);
                console.log('scrollTop oldValue:', oldValue);

            },
        },
        methods: {
         getNews(){
          this.$axios
              .get("/global/website/news/details/"+this.$route.params.id, {
                  headers: {
                      "Content-Type": "application/json",
                  },
              })
              .then((result) => {
                this.news = result.data.data; 
              })
              .catch((err) => {
                  if (err.response) {
                   console.log(err)
                  }

              });
         },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    console.log(document.documentElement.scrollTop, 'documentElement.scrollTop')
                    console.log(document.body.scrollTop, 'scrollTop')
                    console.log(window.innerHeight, 'innerHeight')
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style>
    .home_ad .international-post-items {
        height: auto !important;
    }
</style>