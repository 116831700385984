<template>
    <div> 
        <div class="container-fluid">
            <div class="row"> 
                        <div class="col-lg-7">
                            <div class="about-tab-btn mt-40">
                                <iframe src="https://www.tradays.com/en/economic-calendar/widget?mode=2&amp;dateFormat=DMY" width="100%" height="600px" id="widgetPreview"></iframe>
                              </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-12 mt-5">
                            <RightBar :datas="Posts" :tabs="sideBarTabs" />
                        </div>
                     
            </div>
        </div>

    </div>
</template> 
<script async type="text/javascript" data-type="calendar-widget" src="https://www.tradays.com/c/js/widgets/calendar/widget.js?v=13"> 
</script>
<script>
    import trendingPost from "@/components/Data/HomeTwoPost";
    import RightBar from "@/components/Partials/RightBar.vue";
    import Posts from "@/components/Data/NewsRowCard";

    export default {
        metaInfo () {
    return {
      title: "Economic Calendar - FXNewsNow",
      meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Stay ahead with the latest economic events and forex market impacts using FXNewsNow’s economic calendar.' },
      { name: 'keyword', content: 'economic calendar, forex events, trading calendar' },
    ],
    }
  },
        components: {
            RightBar,
        },
        
        data() {
            return {
                slug:this.$route.params.slug,
                sideBarTabs: [    {
        title: "Weekly Special",
        value: 1,
      },
      {
        title: "Sessional Bonus",
        value: 2,
      },
      {
        title: "Latest",
        value: 3,
      },
            ],
            Posts: Posts.data,
            selected: "latest",
            scrollTop: 0,
            sidebar: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
            };
        }, 
        mounted() {
            document.body.classList.add("home-3-bg");
            window.addEventListener('scroll', this.handleScroll);

        },
        beforeDestroy() {
            document.body.classList.remove("home-3-bg");
            window.removeEventListener('scroll', this.handleScroll);

        },
        created() {
            document.addEventListener("scroll", this.topToBottom);
        },
        watch: {
            $route(to) {
                this.slug = to.params.slug;
            },
            scrollTop(newValue, oldValue) {
                // Do something with the new value or perform additional actions
                console.log('scrollTop changed:', newValue);
                console.log('scrollTop oldValue:', oldValue);

                this.handleScroll();
            },
        },
        methods: {
            handleScroll() {
                // Update the scrollTop data property with the current scroll position
                // this.scrollTop = document.documentElement.scrollTop;
                // let currentTop = 206-this.scrollTop;
                // const ads= document.querySelector(".leftAds");
                // const adsRight= document.querySelector(".rightAds");
                // if(this.scrollTop<=206){
                //   ads.style.top=`${currentTop}px`
                //   adsRight.style.top=`${currentTop}px`
                // }
            },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    console.log(document.documentElement.scrollTop, 'documentElement.scrollTop')
                    console.log(document.body.scrollTop, 'scrollTop')
                    console.log(window.innerHeight, 'innerHeight')
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style>
    .home_ad .international-post-items {
        height: auto !important;
    }
</style>