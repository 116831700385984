<template lang="">
<div class="feature_broker week_program post-international mt-40">
    <div class="section-title">
        <h5 class="title"> {{title}} </h5>
    </div>
    <div class="international-post-items">
      <template v-if="loader">
        <div v-for="item in 2" :key="item" class="row mt-3 mb-2">
          <div class="col-12">
            <PuSkeleton :count="2" height="10px">
            </PuSkeleton>
          </div>
         </div> 
      </template>
      <template v-if="loader==false">
        <div v-for="(item,index) in data" :key="index" class="international-item mb-0">
          <div class="text-thumb d-flex align-items-center">
              <div class="broker_content">
                  <p v-if="item?.name" >
                      <router-link :to="'brokers/profile/'+item.id">
                        <b>Name: </b> {{item.name}}
                        <br>
                        <b>Short Description: </b> {{item.short_description}}
                      </router-link>
                  </p>
                  <p v-else >
                      <router-link :to="'news/details/'+item.slug">
                        {{item.title}}
                      </router-link>
                  </p>
              </div>
          </div>
      </div>
      </template>
 

    </div>
</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        data: {
            type: Array,
            default: () => [],
        },
        loader: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style>
.feature_broker .section-title {
    text-align: center;
    padding: 15px 0px;
    background: #0f91ff;
    border-radius: 30px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.feature_broker .title {
    color: white;
}

.button_wrapper {
    text-align: center;
    display: block;
    justify-content: space-between;
}

.broker_content {
    width:100%;
    padding-left: 20px;
}

.button_wrapper button {
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
}

.feature_broker .international-item:last-child {
    margin-bottom: 40px;
}

.feature_broker .section-title .title {
    font-size: 18px !important;
}
</style>
