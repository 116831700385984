<template>
  <section class="contact-area pb-90">
    <div class="container-fluid">
      <div class="row">
 
        <div class="col-lg-10 offset-lg-1">
          <div class="contact-box">
            <form @submit.prevent="submit()">
              <h3 class="title">
                Let’s work together! <br />
                Fill out the form.
              </h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="text" required v-model="data.name" placeholder="Full name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="text" required v-model="data.subject" placeholder="Subject" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="email" required v-model="data.email" placeholder="Email address" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="number" required v-model="data.phone" placeholder="Phone number" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-box">
                    <textarea
                    required v-model="data.message"
                      cols="30"
                      rows="10"
                      placeholder="Tell us about your message…"
                    ></textarea>
                    <button type="submit" class="main-btn">SEND MESSAGE</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="col-lg-4">
          <div class="contact-sidebar">
            <FromCount />
            <signup-style-one />
          </div>
        </div> -->
     
      </div>
    </div>
  </section>
</template>

<script>
// import FromCount from "../Utility/FollowCount/index";
// import SignupStyleOne from "../Utility/Signup/SignupStyleOne.vue";
export default {
  data() {
    return {
      data: {
          name: null,
          email: null,
          phone:null,
          subject:null,
          message:null,
        },
    }
  },
  methods: {
    submit(){
    
        let fd = new FormData();
    for (const [key, value] of Object.entries(this.data)) {
    if (value !== null) {
      fd.append(key, value);
    }
    } 

    this.$axios
        .post("/global/website/leads", fd, {
    headers: { 
      "Content-Type": "multipart/form-data",
    },
    })
    .then((res) => {
    console.log(res)
    this.$toast.open({
    message: "Message Send Successfully",
    type: 'success',
    }); 
    // push to the home page
    this.$router.push('/');
    })
    .catch((error) => {
    if (error.response) {
        console.log(error.response.data.errors)
        // object of errors
        for (const [key, value] of Object.entries(error.response.data.errors)) {
            key;
            this.$toast.open({
                message: value[0],
                type: 'error',
            });
        }
    }
    });
    },
  },
};
</script>

<style></style>
