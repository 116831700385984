<template lang="">
    <div>
       <div class="container descriptions_wrapper my-5">
           <div class="tabs">
             <ul class="tabs__header">
               <li class="tabs__header-item" @click="getNewsTypes" v-for="(tab,HeadIndex) in tabs" :key="HeadIndex" v-on:click="selectTab(tab.id)"  :class="['nav-link', activeTab === tab.id ? 'active' : '']" >
                   <h6 class='m-0 p-0'>{{tab.name}}</h6>
               </li>
             </ul>
             <div class="tabs__container">
               <ul class="tabs__list" ref="tabsList">
                 <li class="tabs__list-tab mt-3" v-for="(tab,ConIndex) in tabs" :key="ConIndex" v-bind:class="{active: activeTab == tab.id}"> 
                   <div class="row">
                     <div class="col-lg-12 tab_content_wrapper">
                    <div class="row">
                  
                       <template  v-if="loader">
                       
                       <div v-for="item in 3" :key="item" class="col-12 mb-2">
                      <div class="row">
                        <div class="col-2">
                          <PuSkeleton width="70px"  height="40px">
                          </PuSkeleton>
                        </div>
                        <div class="col-10">
                          <PuSkeleton :count="4" height="10px">
                          </PuSkeleton>
                        <div class="mt-3">
                          <PuSkeleton class="mt-2" height="30px" width="80px">
                          </PuSkeleton>
                        </div>
                        </div>
                      </div>
                      
                    
                       </div>
                      </template>
                      
                      <template v-if="loader==false">
                        <div v-for="(item,itemKey) in news" :key="itemKey" class="col-12">
                          <router-link :to="'news/details/'+item.slug">
                              <h4>{{item.title}}</h4>
                          </router-link>
                                     <div class="newsItems">
                                         <div class="leftImg" style="width:60px" >
                                             <img :src="item.image" alt="">
                                         </div>
                                         <div class="rightContent">
                                             <p class="groupItemName mb-4 m-0"> {{item.short_description}} </p>
                                             <router-link :to="'news/details/'+item.slug" class="btn btn-sm btn-primary mb-2" >See More</router-link>
                                         </div>
                                     </div>
                         </div>
                         <!-- <div v-if="news.length==0 && loader==false">
                            <fourOfFour />
                        </div> -->
                      </template>
                  
                    </div>
                     </div> 
                   </div>
                 </li>
               </ul>
             </div>
           </div>
           
          </div>
    </div>
</template>
<script> 
    // import fourOfFour from "@/components/404.vue";

export default { 
  components: {
            // fourOfFour
        },
   data() {
       return {
   activeTab: 1,
   loader: false,
   news:[],
   pagination: {
                grand_total: 0,
        current: 1,
        total: 1,
        perPage: 8,
      },
   tabs: [
 {
   id: 1,
   name: ' Press Release', 
   items:[
       {
       id: 1,
       logo: 'https://placehold.co/210x135',
       name: '1 Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 3,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 3,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 4,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 5,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 6,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
   ],    
}, 
 {
   id: 2,
   name: 'Forex News', 
   items:[
       {
       id: 1,
       logo: 'https://placehold.co/210x135',
       name: '2 Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 3,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 3,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 4,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 5,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 6,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
   ],    
}, 
 {
   id: 3,
   name: 'Crypto Currency', 
   items:[
       {
       id: 1,
       logo: 'https://placehold.co/210x135',
       name: '3 Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 3,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 3,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 4,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 5,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
       {
       id: 6,
       logo: 'https://placehold.co/210x135',
       name: 'Trade lots – get gifts',
       content:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum dicta culpa aliquid porro ex quisquam, vel fuga sint, accusamus asperiores at. Cumque odit accusamus, similique maiores obcaecati amet! Voluptatum atque, doloremque nemo expedita veritatis nostrum suscipit reiciendis ducimus quisquam vel?",
       url:'https://www.google.com'
       }, 
   ],    
}, 
 ],
       };
   },
   created() {
    this.getNewsTypes(4)
   },
   methods: {
    async getNewsTypes(type){
      console.log(type,'type')
      this.loader = true;
      this.news = [];
      var data = {
        perPage: this.pagination.perPage,
        page: this.pagination.current,
        searchText: this.search,
      }; 
            this.$axios
                .get("/global/website/news/type/"+await type, {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                  console.log(result.data.data,'result')
                  this.news = result.data.data;
          this.pagination.current = result.data.meta.current_page;
          this.pagination.total = result.data.meta.last_page;
          this.pagination.grand_total = result.data.meta.total;
          this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                     console.log(err)
                    }

                });
    },
       selectTab(id) { 
     let tabsList = this.$refs.tabsList;
  
        this.activeTab = id;
        if(id == 1){
          this.getNewsTypes(4)
        }else if(id == 2){
          this.getNewsTypes(5)
        }else if(id == 3){
          this.getNewsTypes(6)
        }

         
        tabsList.childNodes.forEach((tab, index) => {
          if (tab.classList.contains('active')) {
            tab.classList.remove('active');
            tab.classList.add('d-none');
          }
          if (index === id - 1) {
            tab.classList.add('active');
            tab.classList.remove('d-none');
          }
        });

   },
 },
}
</script>
<style scoped>
.tabs__header-item.active {
  background: #1091ff;
}
.tabs__header-item.active:hover {
  background: #1091ff;
}
.tabs__header-item.active h6{
  color: white !important;
}
.groupItems {
   display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
   background: white;
   border-left: 3px solid #1091ff;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
   box-shadow: 0 0 8px 0px rgba(0,0,0,.2);
   padding: 10px;
   align-items: center;
}
.tabs {
   width: 100%;
   /* max-width: 400px; */
   margin: 0 auto;
 }
 .tabs__header {
   width: 100%;
   margin: 0;
   padding: 0;
   list-style: none;
   background: #fff;
   border-radius: 4px 4px 0 0;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   display: flex;
   flex-direction: row;
   align-items: center;
 }
 .tabs__header-item {
   flex: 1;
   text-align: center;
   padding: 10px 0;
   cursor: pointer;
   transition: background 0.3s ease;
   border-right: 1px solid rgba(0, 0, 0, 0.1);
   position: relative;
 }
 .tabs__header-item:first-child {
   border-radius: 4px 0 0 0;
 }
 .tabs__header-item:last-child {
   border-radius: 0 4px 0 0;
   border-right: 0;
 }
 .tabs__header-item:hover {
   background: rgba(102, 189, 108, 0.1);
 }

 .tabs__header-item:after {
   content: "";
   width: 0%;
   height: 1px;
   bottom: 0;
   left: 50%;
   position: absolute;
   transform: translate(-50%, 0%);
   /* background: #66bd6c; */
   transition: width 0.3s ease;
 }
 .tabs__header-item.active:after {
   width: 100%;
 }
 .tabs__header-item.active:before {
   background: #66bd6c;
 }
 .tabs__container {
   background: #fff;
   position: relative;
   /* overflow-y: hidden; */
 }
 .tabs__list {
   list-style: none;
   margin: 0;
   padding: 0;
   position: relative;
   display: flex;
   flex-direction: row;
   transition: all 0.3s linear;
 }
 .tabs__list-tab.active {
    opacity: 1;
    display: block !important;
 
  }
 .tabs__list-tab {
   width: 100%;
   min-width: 100%;
   padding: 10px 10px;
   box-sizing: border-box;
   position: relative;
   transition: opacity 0.5s ease;
   opacity: 0;
   display: none !important;
 }
 

 .tab_content_wrapper p{
   color: var(--Gray-Scale-Gray-500, #808080);

/* Body Small/Body Small, 400 */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
 }
 .tab_content_list{
   list-style: none;
 }
 .tab_content_list li{
   color: var(--Gray-Scale-Gray-500, #808080);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
margin-bottom: 10px;
 }

 .newsItems {
    display: flex;
    margin-bottom: 30px;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px !important;
}
.newsItems .leftImg {
    width: 45%;
    margin-right: 20px;
}
.newsItems .leftImg img {
    width: 100% !important;
}
</style>
