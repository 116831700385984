<template>
  <header class="header-area">
    <!-- <top-bar /> -->
    <div class="header-centerbar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4">
            <div class="logo">
              <router-link  to="/">
                <img :src="$store.state.settings?.website_logo ? $store.state.settings.website_logo : require(`@/assets/images/` + image)" alt="feature" class="img-fluid"/>
              </router-link>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="header-centerbar-ad">
              <a v-if="!$store.state.promotions['header-advertisement']" href="#" target="_blank" class="d-block" style="width:728px;height:90px" >
                <img class="img-fluid w-100" src="https://placehold.co/728x90" alt="">
                        </a>
              <a v-else :href="$store.state.promotions['header-advertisement'].bonus_url" target="_blank" class="d-block" style="width:728px;height:90px" >
      <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['header-advertisement'].banner" alt="">
              </a>
          

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-menubar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 col-sm-12 col- px-0 py-4">
            <svg
              class="menu-drawer"
              style="cursor: pointer"
              @click.prevent="hideSidebar"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              fill="currentColor"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"></path>
            </svg>
           
            <nav-items :darkClass="darkClass" />

        


          </div> 
          
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import TopBar from "./TopBar.vue";
import NavItems from "../Common/NavItems.vue";
export default {

  components: {  NavItems },
  props: {
    image: {
      type: String,
      default: "main-logo.png",
      // default: "fx-news-logo.png",
    },
    darkClass: {
      type: Object,
    },
  },
  data: () => ({
    value: null,
    options: ["English", "Bangla", "Hinde"],
  }),
  methods: {
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
  },
};
</script>
<style>

.select-lang {
  border: 1px solid #bababa;
  width: 100px;
  padding: 2px;
  color: #17191d;
  outline: none;
  border-radius: 7px;
}
.logo a {
  width: 44% !important;
  overflow: hidden;
  display: block;
}
</style>
