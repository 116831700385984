<template lang="">
<div class="feature_broker week_program post-international mt-40">
    <div class="section-title">
        <h3 class="title">Bonus By Broker</h3>
    </div>
    <template v-if="loader">
        <div v-for="item in 4" :key="item" class="row mt-3 mb-2">
            <div v-for="it in 3" :key="it" class="col-4">
                <div class="row">
                    <div class="col-3">
                        <PuSkeleton width="80px" height="10px">
                        </PuSkeleton>
                    </div>
                </div>
                <PuSkeleton :count="2" height="10px">
                </PuSkeleton>
            </div>
        </div>
    </template>
    <template v-else>
      <div class="row international-post-items">
        <div v-for="(news, key) in newses" :key="key" class="international-item col-lg-4 col-md-4 col-sm-12 col-12">
            <div class="meta-category">
                <span>{{news.categories[0].name}}</span>
            </div>
            <div class="text-thumb d-flex align-items-center">
                <div class="broker_content">
                    <p>
                        <router-link :to="'/news/details/'+news.slug">
                          {{news.title}}
                        </router-link>
                    </p>
                </div>
            </div>
        </div> 
</div>
    </template>


</div>
</template>

  
<script>
export default {
    data() {
        return {
            loader: false,
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 12,
            },
            newses: [],
        }
    },
    methods: {
        getNews() {
            this.loader = true
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/news/forex-no-deposit-bonus", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.newses = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.loader = false
                })
                .catch((err) => {
                    this.loader = false
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
    },
    created() {
        this.getNews();
    },
}
</script>

  
<style>
.feature_broker .section-title {
    text-align: center;
    padding: 15px 0px;
    background: #0f91ff;
    border-radius: 30px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.feature_broker h3.title {
    color: white;
}

.button_wrapper {
    text-align: center;
    display: block;
    justify-content: space-between;
}

.broker_content {
    padding-left: 20px;
    width:100%;
}

.broker_content p {
    font-size: 14px;
}

.button_wrapper button {
    width: 100%;
    font-size: 14px;
    border-radius: 0px;
    text-transform: uppercase;
}

.feature_broker .international-item:last-child {
    margin-bottom: 40px;
}
</style>
