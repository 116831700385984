<template lang="">
<div class="feature_broker post-international mt-40">
    <div class="section-title">
        <h3 class="title">Forex Featured Brokers</h3>
    </div>
    <div class="international-post-items">
      <template  v-if="loader">
                       
        <div v-for="item in 3" :key="item" class="col-12 mb-2">
       <div class="row">
         <div class="col-2">
           <PuSkeleton width="70px" height="70px">
           </PuSkeleton>
         </div>
         <div class="col-10">
           <PuSkeleton :count="2" height="10px">
           </PuSkeleton>
         <div class="mb-3 row">
          <div class="col-6">
            <PuSkeleton class="mt-2" height="35px" width="180px">
            </PuSkeleton>
          </div>
          <div class="col-6">
            <PuSkeleton class="mt-2" height="35px" width="180px">
            </PuSkeleton>
          </div>
         </div>
         </div>
       </div>
        </div>
       </template>
       <template v-if="loader==false">
        <div class="international-item" v-for="(broker,ind) in brokers.slice(0,3)" :key="ind">
          <div class="text-thumb d-flex align-items-center">
              <div style="width:70px;height:70px" class="thumb"> 
                <img :src="broker.profile" alt="" class="w-100 h-100" /> 
              </div>
              <div class="broker_content">
                  <p> {{broker.short_description}} </p>
                  <div class="button_wrapper"> 
                    <a :href="broker.live_url" class='btn btn-sm
btn-primary'> Start Trading </a> 
<router-link v-if="visit" :to="'/brokers/profile/'+broker.id" class='btn btn-dark'> Visit
                      </router-link> </div>
              </div>
          </div>
      </div> 
       </template>
     
    </div>
</div>
</template>

<script>
export default {
    props: {
        visit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loader: false,
            brokers: [],
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 8,
            },
        };
    },
    methods: {
        getBrokers() {
            this.loader = true;
            this.brokers = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/broker/feature", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    console.log(result.data.data, "result");
                    this.brokers = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
    },
    created() {
        this.getBrokers();
    },
};
</script>

<style scoped>
.feature_broker .section-title {
    text-align: center;
    padding: 15px 0px;
    background: #0f91ff;
    border-radius: 30px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.feature_broker h3.title {
    color: white;
}

.button_wrapper {
    text-align: center;
    display: flex !important;
    justify-content: space-between;
}

.broker_content {
    padding-left: 20px;
    width:100%;

}

.button_wrapper a {
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
}

.feature_broker .international-item:last-child {
    margin-bottom: 40px;
}
</style>
