<template>
<div :class="[
        'post_gallery_sidebar',
        darkClass && 'section' in darkClass ? darkClass.section : '',
      ]">
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
        <li v-for="(tab,tabKey) in tabs" :key="tabKey" class="nav-item" @click.prevent="selectGalleryTab(tab.value)">
            <a :class="['nav-link', selectedGallery === tab.value ? 'active' : '']" data-toggle="pill" role="tab" aria-controls="pills-home" aria-selected="true">{{tab.title}}</a>
        </li>
    </ul>


    <template v-if="loader">
        <div v-for="item in 5" :key="item" class="row mt-3 mb-2">
            <div class="col-3">
                <PuSkeleton width="100px" height="60px">
                </PuSkeleton>
            </div>
            <div class="col-9">
                <div class="row">
                    <div v-for="item in 2" :key="item" class="col-3">
                        <PuSkeleton width="80px" height="10px">
                        </PuSkeleton>
                    </div>
                </div>
                <PuSkeleton :count="2" height="10px">
                </PuSkeleton>
            </div>
        </div>
    </template>

    <template v-if="loader==false">
        <div v-for="(tabsList,tabListKey) in tabs" :key="tabListKey" class="tab-content">
            <div :class="[
              'tab-pane fade',
              selectedGallery === tabsList.value ? 'show active' : '',
            ]" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="post_gallery_items" v-if="news && news.length > 0">
                    <template v-for="(item, index) in news">
                        <router-link :to="'/news/details/'+item.slug" :key="index" class="gallery_item">

                            <div class="gallery_item_thumb" style="width:100px">
                                <img :src="item.image" alt="gallery" />
                            </div>
                            <div class="gallery_item_content">
                                <div class="post-meta">
                                    <div class="meta-categories">
                                        <router-link :to="'/news/details/'+item.slug" :key="index">{{ item.categories[0].name }}</router-link>
                                    </div>
                                    <div class="meta-date">
                                        <span>{{ item.created_at }}</span>
                                    </div>
                                </div>
                                <h4 class="title">
                                    <router-link :to="'/news/details/'+item.slug" :key="index">{{ item.title }}</router-link>
                                </h4>
                            </div>
                        </router-link>
                        <!-- <row-card
                  :class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
                  :category="true"
                  :datas="small"
                  :key="index"
                /> -->
                    </template>
                </div>
            </div>
        </div>
    </template>

    <div class="featureBonus mt-5">
        <h4 class="featureBonusTitle">Feature Bonus</h4>
        <div class="featureBonusItemWrapper">
            <ul class="featureBonusItems">
                <template v-if="bonusLoader">
                    <div v-for="item in 10" :key="item" class="row mt-3 mb-2">
                        <div class="col-1">
                            <PuSkeleton width="30px" height="10px">
                            </PuSkeleton>
                        </div>
                        <div class="col-11">
                            <PuSkeleton :count="1" height="10px">
                            </PuSkeleton>
                        </div>
                    </div>
                </template>
    <template v-if="bonusLoader==false">
                <li v-for="(news,index) in featureBonus" :key="index" class="featureBonusItemsName">
                    <router-link :to="'/news/details/'+news.slug">
                        <i class="fa fa-angle-double-right"> </i>
                    {{ news.title }}
                    </router-link>
                </li> 
    </template>
            </ul>
        </div>
    </div>
    <!-- featureBonus end  -->
    <div class="adsSideBar mt-4 rounded">
        <div class="card text-center p-2">
            <div class="international-post-items">
                <div class="international-item"> 
                  <a v-if="!$store.state.promotions['box-advertisement-one']" href="#">
                    <img src="https://placehold.co/450x405" class="img-fluid w-100" alt="" />
                </a>
                <a v-else :href="$store.state.promotions['box-advertisement-one'].bonus_url" target="_blank" class="d-block" style="width:450px;height:405px" >
                  <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['box-advertisement-one'].banner" alt="">
                </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

  
  
<script>
// import RowCard from "@/components/Utility/Cards/RowCard.vue";
export default {
    // components: { RowCard },
    props: {
        datas: {
            type: Array,
            default: () => [],
        },
        tabs: {
            type: Array,
            default: () => [],
        },
        darkClass: {
            type: Object,
            default: () => {},
        },
        signup: {
            type: Boolean,
            default: true,
        },
        trendingBigPost: {
            type: Boolean,
            default: true,
        },
        trendingShortPost: {
            type: Boolean,
            default: true,
        },
        ad: {
            type: Boolean,
            default: true,
        },
        sharePost: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loader: false,
            bonusLoader: false,
            news: [],
            featureBonus: [],
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 5,
            },
            selectedGallery: this.tabs.length > 0 ? this.tabs[0].value : "",
            trendingNews: {
                autoplay: true,
                slideToScroll: 1,
                slidesToShow: 1,
                arrows: false,
                dots: false,
            },
        };
    },
    methods: {
        getNewsTypes(type) {
            this.loader = true;
            this.news = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/news/type/" + type, {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    console.log(result.data.data, 'result')
                    this.news = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
        getFeatureBonus() {
            this.bonusLoader = true;
            this.news = [];
            var data = {
                perPage: 10,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/news/type/4", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => { 
                    this.featureBonus = result.data.data; 
                    this.bonusLoader = false;
                })
                .catch((err) => {
                    this.bonusLoader = false;
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
        selectGalleryTab(value) {
            this.selectedGallery = value;
            this.getNewsTypes(value)
        },
        trendingNewsPrev() {
            this.$refs.trendingNews.prev();
        },
        trendingNewsNext() {
            this.$refs.trendingNews.next();
        },
    },
    created() {
        this.getNewsTypes(1)
        this.getFeatureBonus()
    },
};
</script>
  
  
<style>
ul.featureBonusItems {
    list-style: none;
    padding: 0px;
}

ul.featureBonusItems a {
    transition: .5s ease-in-out;
    padding: 10px 0px;
    display: block;
    border: 1px solid rgb(23 34 43 / 16%);
    margin: 9px 0px;
    border-radius: 10px;
    padding-left: 9px;
    background: white;
}

ul.featureBonusItems a:hover {
    background: #1091ff;
    color: white;
    padding-left: 15px;
}
</style>
