<template>
  <div id="app">
    <video-pop-up />
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <header-two @toggleSidebar="toggleSidebar" />
    <!-- {{ $route.path }} -->
    <div class="row">
      <div class="reviewAdsWrapper col-lg-1 col-md-1 col-sm-0 col-none pl-0">
        <div class="review-add-area text-center">
          <a v-if="!$store.state.promotions['left-side-advertisement-one']" href="#">
            <img src="https://placehold.co/107x600" class="img-fluid w-100" alt="" />
        </a>
        <a v-else :href="$store.state.promotions['left-side-advertisement-one'].bonus_url" target="_blank" class="d-block" style="width:107px;height:600px" >
          <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['left-side-advertisement-one'].banner" alt="">
                  </a>
        </div>
        <div class="review-add-area mt-5 text-center">
          <a v-if="!$store.state.promotions['left-side-advertisement-two']" href="#">
            <img src="https://placehold.co/107x600" class="img-fluid w-100" alt="" />
        </a>
        <a v-else :href="$store.state.promotions['left-side-advertisement-two'].bonus_url" target="_blank" class="d-block" style="width:107px;height:600px" >
          <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['left-side-advertisement-two'].banner" alt="">
                  </a>
        </div>
    </div>
    <div class="col-lg-10">
      <router-view />
    </div>

    <div class="reviewAdsWrapper col-lg-1 col-md-1 col-sm-0 col-none pr-0">
      <div class="review-add-area text-center">
        <a v-if="!$store.state.promotions['right-side-advertisement-one']" href="#">
          <img src="https://placehold.co/107x600" class="img-fluid w-100" alt="" />
      </a>
      <a v-else :href="$store.state.promotions['right-side-advertisement-one'].bonus_url" target="_blank" class="d-block" style="width:107px;height:600px" >
        <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['right-side-advertisement-one'].banner" alt="">
                </a>
      </div>
      <div class="review-add-area mt-5 text-center">
        <a v-if="!$store.state.promotions['right-side-advertisement-one']" href="#">
          <img src="https://placehold.co/107x600" class="img-fluid w-100" alt="" />
      </a>
      <a v-else :href="$store.state.promotions['right-side-advertisement-one'].bonus_url" target="_blank" class="d-block" style="width:107px;height:600px" >
        <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['right-side-advertisement-one'].banner" alt="">
                </a>
      </div>
  </div>
    </div>

    <TopBrands />
    <!-- <Footer /> -->
    <MainFooter />

    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBrands from "@/components/Partials/TopBrands.vue";
// import Footer from "@/components/Partials/Footer.vue";
import MainFooter from "@/components/Partials/MainFooter.vue";
import HeaderTwo from "@/components/Utility/Header/index";
import VideoPopUp from "@/components/Utility/VideoPopUp.vue"; 
import Drawer from "@/components/Mobile/Drawer.vue"; 

export default {

  components: {
    HeaderTwo,TopBrands,VideoPopUp,Drawer,
    MainFooter,
    // Footer
  },
  data() {
    return {
      scrollTop: 0,
    sidebar: false,
    data: this.$store.state,
    }
  },
  mounted() {
    document.body.classList.add("home-3-bg");
        this.$store.dispatch("getPromotions");
      },
      beforeMount() {
        this.$store.dispatch("getSettings");
  },
  beforeDestroy() {
    document.body.classList.remove("home-3-bg");

  },
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },  
  watch: {
    // Watch the scrollTop property for changes
    scrollTop(newValue, oldValue) {
      // Do something with the new value or perform additional actions
      console.log('scrollTop changed:', newValue);
      console.log('scrollTop oldValue:', oldValue);
    },
    // data watcher
    data: {
      handler() {
        this.changeFavicon();
      },
      deep: true,
    },
  },
  methods: { 
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    changeFavicon() {
  var link;
    link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = this.data.settings?.website_favicon ? this.data.settings.website_favicon : '/favicon.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
}
  },
  // metaInfo: {
  //   meta: [
  //     { charset: 'utf-8' },
  //     { vmid: 'TITLE', name: 'TITLE', content: 'foo' }
  //   ]
  //   },

}
</script>


<style>

</style>