<template>
  <div>
    <div class="aboutWrapper">
      <div class="container">
        <div class="row">

          <div class="col-lg-12 col-md-12 col-12">
            <div class="card mt-4">
              <div class="card-header">
                  <h4 class="card-title
">About US</h4>
              </div>
              <div class="card-body about-content">
                  <div v-html="page.content"></div>

              </div>
          </div>
        
          </div>
       
        </div>
      </div>
      
    </div>

    <div class="add-area text-center my-5 pb-5">
      <a href="#" class="mb-5">
        <img src="@/assets/images/ads/one_ad.png" alt="" />
      </a>
    </div>
 
  </div>
</template>

<script>
export default {
  components: {
  },
    data() {
            return {
                page:null,
                sidebar: false,
            }
        },
        created() {
            this.getPage()
            document.addEventListener("scroll", this.topToBottom);
        },
        methods: {
            getPage(){
                this.$axios
                .get("/global/website/page/about", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    console.log(result.data.data, 'result')
                    this.page=result.data.data;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                     console.log(err)
                    }

                });
            },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style>
 
</style>
