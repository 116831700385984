<template>
  <section class="post-footer-style-2-area pb-100">
    <div class="container custom-container">
      <div class="row">
        <div class="col-lg-8">
          <div class="mt-40">
         
            <a v-if="!$store.state.promotions['bottom-advertisement-large']" href="#">
              <img src="https://placehold.co/730x90" class="img-fluid w-100" alt="" />
          </a>
          <a v-else :href="$store.state.promotions['bottom-advertisement-large'].bonus_url" target="_blank" class="d-block" style="width:730px;height:90px" >
            <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['bottom-advertisement-large'].banner" alt="">
          </a>

          </div> 
        </div>
        <div class="col-lg-4">
          
          <div class="post-footer-sidebar">
            <div class="mt-40">
              <a v-if="!$store.state.promotions['bottom-advertisement-small']" href="#">
                <img src="https://placehold.co/350x90" class="img-fluid w-100" alt="" />
            </a>
            <a v-else :href="$store.state.promotions['bottom-advertisement-small'].bonus_url" target="_blank" class="d-block" style="width:350px;height:90px" >
              <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['bottom-advertisement-small'].banner" alt="">
            </a>
            </div> 
          </div>
         
        </div>
      </div>
    </div>
  </section>
</template>

<script> 
export default {
  components: {   },
  props: {
    sports: {
      type: Array,
    },
    otherfinance: {
      type: Array,
    },
  },
};
</script>

<style></style>
