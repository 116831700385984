<template>
    <div> 
        <div class="container-fluid"> 
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="about-tab-btn mt-40">
                              <div> 
                              </div>
                                <div class="about-post-items">
                                  <template v-if="loading">
                                    <div v-for="item in 3" :key="item" class="row mt-3 mb-3 px-2">
                                      <div class="col-6" v-for="item in 2" :key="item">
                                          <div class="row">
                                              <div :key="item" class="col-12">
                                                  <PuSkeleton  height="180px">
                                                  </PuSkeleton>
                                              </div>
                                              <div v-for="item in 2" :key="item" class="col-4">
                                                <PuSkeleton  height="10px">
                                                </PuSkeleton>
                                              </div>
                                          </div>
                                          <PuSkeleton :count="2" height="10px">
                                          </PuSkeleton>
                                      </div>
                                  </div>
                                </template>
                                <template v-else>
                                  <div class="row">
                                 <div class="col-12">
                                    <table class="table table-light">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>Exchange</th>
                                                <th>Ticker Symbol</th>
                                                <th>Price</th>
                                            </tr> 
                                        </thead>
                                        <tbody>
                                            <tr v-for="(coin, index) in coins" :key="index">
                                                <td>
                                                    <div class="d-flex align-content-center align-items-center align-self-center ">
                                                        <img :src="coin.webp32" class="mr-2">
                                                            <p class="mb-0">
                                                                {{coin.name}}
                                                            </p>
                                                    </div>
                                                </td>
                                                <td>{{coin.code}}</td>
                                                <td>{{coin.rate.toFixed(2)}}</td>
                                            </tr> 
                                        </tbody>
                                    </table>
                                 </div>


                                    <div v-if="coins.length==0">
                                      <!-- no data found  -->
                                        <fourOfFour />
                                      
                                    </div>
 
                                  </div>
                                </template>

                                </div>
                              </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-12 mt-5">
                            <RightBar :datas="Posts" :tabs="sideBarTabs" />
                        </div>
                    </div>
               
        </div>
 
    </div>
</template>

<script>
    import trendingPost from "@/components/Data/HomeTwoPost";
    import RightBar from "@/components/Partials/RightBar.vue";
    import Posts from "@/components/Data/NewsRowCard";
    import fourOfFour from "@/components/404.vue";

    export default {
        metaInfo () {
    return {
      title: "Popular Coins - FXNewsNow",
      meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Discover the most popular cryptocurrencies and their latest trends with FXNewsNow.' },
      { name: 'keyword', content: 'popular cryptocurrencies, top coins, crypto trends' },
    ],
    }
  },
        components: {
            RightBar,fourOfFour
        },
        
        data() {
            return {
              pagination: {
                grand_total: 0,
        current: 1,
        total: 1,
        perPage: 8,
      },
      items: [5, 10, 15, 20, 40, 50, 100],
                newses: [],
                coins: [],
                slug:this.$route.params.slug,
                sideBarTabs: [    {
        title: "Weekly Special",
        value: 1,
      },
      {
        title: "Sessional Bonus",
        value: 2,
      },
      {
        title: "Latest",
        value: 3,
      },
            ],
            Posts: Posts.data,
            selected: "latest",
            scrollTop: 0,
            sidebar: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
            loading:false
            };
        }, 
        mounted() {
            document.body.classList.add("home-3-bg");
            window.addEventListener('scroll', this.handleScroll);
          this.getNews(this.slug)
        },
        beforeDestroy() {
            document.body.classList.remove("home-3-bg");
            window.removeEventListener('scroll', this.handleScroll);

        },
        created() {
            this.getPopularCoins()
            document.addEventListener("scroll", this.topToBottom);
        },
        watch: {
            $route(to) {
                this.slug = to.params.slug;
                this.getNews(this.slug)
            },
            scrollTop(newValue, oldValue) {
                // Do something with the new value or perform additional actions
                console.log('scrollTop changed:', newValue);
                console.log('scrollTop oldValue:', oldValue);

                this.handleScroll();
            },
        },
        methods: {
            getPopularCoins(){
                let data =
                {
                    currency: "USD",
    sort: "rank",
    order: "ascending",
    offset: 0,
    limit: 50,
    meta: true,
                }
                this.$axios
                .post("https://api.livecoinwatch.com/coins/list", data, {
    headers: { 
    "content-type": "application/json",
                        "x-api-key": "fd0bd8b9-ac0f-4bfe-9559-4a3e26a13915",
    },
    })
                .then((result) => {
          this.loading=false
                        this.coins = result.data
                }).catch((err) => {
                    if (err.response) {
                     console.log(err)
                    }

                });
            },
          getNews(slug){
            this.loading=true
            var data = {
        perPage: this.pagination.perPage,
        page: this.pagination.current,
        searchText: this.search,
      };
            this.$axios
                .get("/global/website/news/"+slug, {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                  this.newses = result.data.data;
          this.pagination.current = result.data.meta.current_page;
          this.pagination.total = result.data.meta.last_page;
          this.pagination.grand_total = result.data.meta.total;
          this.loading=false

                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                     console.log(err)
                    }

                });
          },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    console.log(document.documentElement.scrollTop, 'documentElement.scrollTop')
                    console.log(document.body.scrollTop, 'scrollTop')
                    console.log(window.innerHeight, 'innerHeight')
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style>
    .home_ad .international-post-items {
        height: auto !important;
    }
</style>