<template>
<div>
    <div class="brokerReviewWrapper my-5 row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="card mt-4">
                <div class="card-header">
                    <h4 class="card-title
                      ">The Best Forex Brokers in {{slug}}</h4>
                </div>
                <div v-if="slug!='ecn'" class="card-body about-content">
                    Discover the top Forex brokers in Indonesia that offer competitive spreads, reliable trading platforms, and exceptional customer support. Start your trading journey with confidence and maximize your potential for success in the forex market. Compare and choose from the best brokers to take advantage of lucrative opportunities today.
                </div>
                <div v-if="slug=='ecn'" class="card-body about-content">
                    An ECN (Electronic Communication Network) broker is a type of financial intermediary that facilitates direct trading between buyers and sellers in the forex and CFD (Contract for Difference) markets. Here are the key features and benefits of ECN brokers:

                    Key Features of ECN Brokers
                    Direct Market Access:
                    
                    Function: ECN brokers provide direct access to the interbank market, where large financial institutions trade. This means you can trade directly with other market participants rather than through a dealing desk.
                    No Dealing Desk:
                    
                    Function: Unlike market makers, ECN brokers do not take the other side of your trades. They match buy and sell orders from different traders, reducing the risk of conflicts of interest.
                    Transparent Pricing:
                    
                    Function: ECN brokers typically offer raw spreads, which means you see the true market spreads without mark-ups. Spreads can start from as low as 0.0 pips, though a commission may apply.
                    High Liquidity:
                    
                    Function: By connecting to multiple liquidity providers, ECN brokers offer high liquidity, which helps in executing trades quickly and at desired prices.
                    Order Types:
                    
                    Function: ECN brokers support various order types and advanced trading features, including limit, market, stop-loss, and trailing stop orders.
                    Low Latency:
                    
                    Function: With direct access to the market, ECN brokers generally offer low latency in trade execution, which is crucial for high-frequency and algorithmic traders.
                    Benefits of Using an ECN Broker
                    Tighter Spreads: Raw spreads can be very tight, providing cost-effective trading for those who prefer to pay a commission rather than a mark-up on the spread.
                    No Conflict of Interest: Since ECN brokers do not act as market makers, there is no inherent conflict of interest between the broker and the trader.
                    Enhanced Transparency: All orders are matched in the order book, providing a clear view of market depth and price movements.
                    Better Execution: Faster order execution and reduced slippage due to high liquidity and direct market access.
                    Considerations
                    Commission Costs: ECN brokers usually charge a commission on trades, which can vary based on the broker and account type.
                    Minimum Deposit: ECN accounts often require a higher minimum deposit compared to standard accounts.
                    Suitable for Active Traders: ECN accounts are often favored by active traders and those who engage in high-frequency trading due to the low spreads and fast execution.
                    Overall, ECN brokers are ideal for traders who seek transparent pricing, direct market access, and are comfortable with paying a commission for the benefits of raw spreads and high liquidity.
                    
                </div>
            </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 col-12">

            <!-- Brokers List -->
            <div class="card mt-5">

                <div class="card-body about-content">
                    <template v-if="loader">

                        <div v-for="item in 5" :key="item" class="col-12 mb-2">
                            <div class="row">
                                <div class="col-3">
                                    <PuSkeleton width="50px" height="50px">
                                    </PuSkeleton>
                                </div>
                                <div class="col-9">
                                    <PuSkeleton v-for="item in 2" :key="item" height="10px">
                                    </PuSkeleton>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <table class="table table-light table-hover table-striped table-responsive-lg">
                            <thead>
                                <tr>
                                    <th>Broker</th>
                                    <th>Rating</th>
                                    <th>Min Deposit</th>
                                    <th width="120px">Regulation</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(broker,index) in brokers" :key="index">
                                    <td>
                                        <router-link :to="'/brokers/profile/'+broker.id">
                                            <div class="brokerThumb d-flex align-items-center">
                                                <img :src="broker.profile" width="40px" />
                                                <p class="brokerContent ml-2 mb-0">
                                                    {{broker.name}}
                                                </p>
                                            </div>
                                        </router-link>
                                    </td>
                                    <td>{{broker.ratings_count}}</td>
                                    <td>{{broker.min_deposit}}</td>
                                    <td>
                                        <span v-for="(regulation,index) in broker.regulations" :key="index" class="badge badge-primary mr-1">{{regulation.name}}</span> </td>
                                    <td><a :href="broker.live_url" target="_blank" class='btn btn-sm btn-primary'>Open Account</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-lg-12">
                            <div class="pagination-item">
                                <pagination :edgeNavigation="true" v-model="pagination.current" :per-page="pagination.perPage" :records="pagination.grand_total" @paginate="getBroker(slug)" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- Right SideBar  -->
        <div class="col-lg-5 col-md-6 col-12 mt-5">
            <RightBar :tabs="sideBarTabs" />
        </div>

    </div>
    <div class="review-add-area text-center mb-5">

        <a v-if="!$store.state.promotions['review-page-bottom-advertisement']" href="#">
            <img src="https://placehold.co/730x90" class="img-fluid w-100" alt="" />
        </a>
        <a v-else :href="$store.state.promotions['review-page-bottom-advertisement'].bonus_url" target="_blank" style="width:730px;height:90px">
            <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-bottom-advertisement'].banner" alt="">
        </a>
    </div>
</div>
</template>

<script>
import RightBar from "@/components/Partials/RightBar.vue";

export default {
    metaInfo () {
    return {
      title: "Best Forex Brokers in the "+this.$route.params.country+" - FXNewsNow",
      meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Compare and find the best forex brokers in various regions with FXNewsNow’s expert recommendations.' },
      { name: 'keyword', content: 'best forex brokers, top forex brokers, forex trading' },
    ],
    }
  },
    components: {
        RightBar
    },
    data() {
        return {
            sideBarTabs: [{
                    title: "Weekly Special",
                    value: 1,
                },
                {
                    title: "Sessional Bonus",
                    value: 2,
                },
                {
                    title: "Latest",
                    value: 3,
                },
            ],
            sidebar: false,
            slug: this.$route.params.country,
            brokers: [],
            loader: false,
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 10,
            },
        };
    },
    watch: {
        $route(to) {
            this.slug = to.params.country;
            this.getBroker(this.slug);

        },
    },
    created() {
        document.addEventListener("scroll", this.topToBottom);
        this.getBroker(this.slug);
    },
    methods: {
        getBroker(slug) {
            this.loader = true;
            this.brokers = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/broker/bycountry/" + slug, {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.brokers = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },
    },
};
</script>

<style >

</style>
