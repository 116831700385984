<template>
<div>
    <div class="brokerReviewWrapper my-5 row">

        <div class="col-lg-8 col-md-8 col-sm-12 col-12">

            <!-- tabs container  -->
            <div class="container descriptions_wrapper my-5">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li class="tabs__header-item" @click="getNewsTypes" v-for="(tab,HeadIndex) in tabs" :key="HeadIndex" v-on:click="selectTab(tab.id)" :class="['nav-link', activeTab === tab.id ? 'active' : '']">
                            <h6 class='m-0 p-0'>{{tab.name}}</h6>
                        </li>
                    </ul>
                    <div class="tabs__container">
                        <ul class="tabs__list" ref="tabsList">
                            <li class="tabs__list-tab mt-3" v-for="(tab,ConIndex) in tabs" :key="ConIndex" v-bind:class="{active: activeTab == tab.id}">
                                <div class="row">
                                    <div class="col-lg-12 tab_content_wrapper">
                                        <div class="row">
                                            <template v-if="loader">

                                                <div v-for="item in 2" :key="item" class="col-12 mb-2">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <PuSkeleton width="50px" height="50px">
                                                            </PuSkeleton>
                                                        </div>
                                                        <div class="col-9">
                                                            <PuSkeleton v-for="item in 2" :key="item" height="10px">
                                                            </PuSkeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-if="loader==false">
                                                <div class="col-12 ">
                                                    <div class="card mt-5">
                                                        <div class="card-header">
                                                            <h5 class="card-title text-center mb-0">Search Results for – " {{search}} " </h5>
                                                        </div>
                                                        <div class="card-body about-content">
                                                         
                                                            <template v-if="activeTab==1">
                                                                <table class="table table-light table-hover table-striped table-responsive-lg">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Broker</th>
                                                                            <th>Rating</th>
                                                                            <th>Min Deposit</th>
                                                                            <th width="140px">Regulation</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(broker,index) in data" :key="index">
                                                                            <td>
                                                                                <router-link :to="'/brokers/profile/'+broker.id">
                                                                                    <div class="brokerThumb d-flex align-items-center">
                                                                                        <img :src="broker.profile" width="40px" />
                                                                                        <p class="brokerContent ml-2 mb-0">
                                                                                            {{broker.name}}
                                                                                        </p>
                                                                                    </div>
                                                                                </router-link>
                                                                            </td>
                                                                            <td>
                                                                                <!-- {{broker.ratings_count}} -->
                                                                                <div class="rating">
                                                                                    <!-- rating with half star -->
                                                                                    <div class="rating-wrap" v-if="broker.ratings_count!=0">

                                                                                        <span class="star" v-for="i in 5" :key="i">
                                                                                            <i v-if="broker.ratings_count >= i" class="fa fa-star"></i>
                                                                                            <i v-else-if="broker.ratings_count > i - 1" class="fa fa-star" style="clip-path: inset(0 50% 0 0);"></i>
                                                                                        </span>

                                                                                    </div>
                                                                                    <div class="rating-wrap" v-else>

                                                                                        0

                                                                                    </div>
                                                                                </div>

                                                                            </td>
                                                                            <td>{{broker.min_deposit}}</td>
                                                                            <td>
                                                                                <span v-for="(regulation,index) in broker.regulations" :key="index" class="badge badge-primary mr-1">{{regulation.name}}</span> </td>
                                                                            <td><a :href="broker.live_url" target="_blank" class='btn btn-sm btn-primary'>Open Account</a></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div class="col-lg-12">
                                                                    <div class="pagination-item">
                                                                        <pagination :edgeNavigation="true" v-model="pagination.current" :per-page="pagination.perPage" :records="pagination.grand_total" @paginate="searchAll()" />
                                                                    </div>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <div v-for="(item,itemKey) in data" :key="itemKey" class="col-12">
                          <router-link :to="'news/details/'+item.slug">
                              <h4>{{item.title}}</h4>
                          </router-link>
                                     <div class="newsItems">
                                         <div class="leftImg" style="width:60px" >
                                             <img :src="item.image" alt="">
                                         </div>
                                         <div class="rightContent">
                                             <p class="groupItemName mb-4 m-0"> {{item.short_description}} </p>
                                             <router-link :to="'news/details/'+item.slug" class="btn btn-sm btn-primary mb-2" >See More</router-link>
                                         </div>
                                     </div>
                         </div>
                         <div class="col-lg-12">
                                                                    <div class="pagination-item">
                                                                        <pagination :edgeNavigation="true" v-model="pagination.current" :per-page="pagination.perPage" :records="pagination.grand_total" @paginate="searchAll()" />
                                                                    </div>
                                                                </div>
                                                            </template>

                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <!-- tabs container  -->

        </div>

        <!-- Right SideBar  -->
        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <div class="featureBroker">
                <FeaturedBroker :visit="false" />
            </div>
            <div class="adsSideBar mt-4 rounded">
                <div class="card text-center p-2">
                    <div class="international-post-items">
                        <div class="international-item">
                            <a v-if="!$store.state.promotions['review-page-box-advertisement-one']" href="#">
                                <img src="https://placehold.co/360x325" class="img-fluid w-100" alt="" />
                            </a>
                            <a v-else :href="$store.state.promotions['review-page-box-advertisement-one'].bonus_url" target="_blank" class="d-block" style="width:360px;height:325px">
                                <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-box-advertisement-one'].banner" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="drawBroker">
                <WeekProgram title="Draw Bonus" :data="drawNews" :loader="loader" />
            </div>
            <div class="adsSideBar mt-4 rounded">
                <div class="card text-center p-2">
                    <div class="international-post-items">
                        <div class="international-item">
                            <a v-if="!$store.state.promotions['review-page-box-advertisement-two']" href="#">
                                <img src="https://placehold.co/360x325" class="img-fluid w-100" alt="" />
                            </a>
                            <a v-else :href="$store.state.promotions['review-page-box-advertisement-two'].bonus_url" target="_blank" class="d-block" style="width:360px;height:325px">
                                <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-box-advertisement-two'].banner" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="review-add-area text-center mb-5">

        <a v-if="!$store.state.promotions['review-page-bottom-advertisement']" href="#">
            <img src="https://placehold.co/730x90" class="img-fluid w-100" alt="" />
        </a>
        <a v-else :href="$store.state.promotions['review-page-bottom-advertisement'].bonus_url" target="_blank" style="width:730px;height:90px">
            <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-bottom-advertisement'].banner" alt="">
        </a>
    </div>

</div>
</template>

<script>
import FeaturedBroker from "@/components/HomeTwo/FeaturedBroker.vue";
import WeekProgram from "@/components/HomeTwo/WeekProgram.vue";

export default {
            metaInfo () {
    return {
      title: "You search for " + this.$route.query.q,
    }
  },
    components: {
        FeaturedBroker,
        WeekProgram
    },
    data() {
        return {
            search: this.$route.query.q,
            drawNews: [],
            featureBrokers: [],
            data: [],
            sidebar: false,
            broLoader: false,
            loader: false,
            brokerLoader: false,
            activeTab: 1,
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 10,
            },

            tabs: [{
                    id: 1,
                    name: 'Brokers',

                },
                {
                    id: 2,
                    name: 'Others',

                },
            ],
        }
    },
    watch: {
        $route(to) {
            this.search = to.query.q;
            if (!to.query.q) {
                this.$router.push('/');
            } else {
                this.searchAll()
            }
        },
    },
    created() {
        // before enter the route check if the query is empty
        if (!this.$route.query.q) {
            this.$router.push('/');
        } else {
            this.searchAll()
        }
        document.addEventListener("scroll", this.topToBottom);
        console.log(this.$route.query, "query");
        this.getDrawBonus()
        // this.getBrokers()
        this.getTopBrokers()
    },

    methods: {
        selectTab(id) {
            let tabsList = this.$refs.tabsList;
            this.activeTab = id;
            this.searchAll()
            tabsList.childNodes.forEach((tab, index) => {
                if (tab.classList.contains('active')) {
                    tab.classList.remove('active');
                    tab.classList.add('d-none');
                }
                if (index === id - 1) {
                    tab.classList.add('active');
                    tab.classList.remove('d-none');
                }
            });

        },
        searchAll() {
                    this.loader = true;

            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
                activeTab: this.activeTab
            };
            this.$axios
                .get("/global/website/search", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    console.log(result, 'resss')
                    this.data = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;

                    if (err.response) {
                        console.log(err);
                    }
                });
        },
        getBrokers() {
            this.broLoader = true;
            this.brokers = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/brokers", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    console.log(result.data.data, "result");
                    this.brokers = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.broLoader = false;
                })
                .catch((err) => {
                    this.broLoader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
        getTopBrokers() {
            this.brokerLoader = true;
            this.featureBrokers = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/broker/feature", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.featureBrokers = result.data.data;
                    this.brokerLoader = false;
                })
                .catch((err) => {
                    this.brokerLoader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
        getDrawBonus() {
            this.loader = true
            var data = {
                perPage: 5,
                page: 1,
            };
            this.$axios
                .get("/global/website/news/contest", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.drawNews = result.data.data;
                    this.loader = false
                })
                .catch((err) => {
                    this.loader = false
                    if (err.response) {
                        console.log(err)
                    }

                });
        },

        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },
    },
};
</script>

<style scoped>
.tabs__header-item.active {
    background: #1091ff;
}

.tabs__header-item.active:hover {
    background: #1091ff;
}

.tabs__header-item.active h6 {
    color: white !important;
}

.groupItems {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: white;
    border-left: 3px solid #1091ff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, .2);
    padding: 10px;
    align-items: center;
}

.tabs {
    width: 100%;
    /* max-width: 400px; */
    margin: 0 auto;
}

.tabs__header {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tabs__header-item {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    transition: background 0.3s ease;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
}

.tabs__header-item:first-child {
    border-radius: 4px 0 0 0;
}

.tabs__header-item:last-child {
    border-radius: 0 4px 0 0;
    border-right: 0;
}

.tabs__header-item:hover {
    background: rgba(102, 189, 108, 0.1);
}

.tabs__header-item:after {
    content: "";
    width: 0%;
    height: 1px;
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%);
    /* background: #66bd6c; */
    transition: width 0.3s ease;
}

.tabs__header-item.active:after {
    width: 100%;
}

.tabs__header-item.active:before {
    background: #66bd6c;
}

.tabs__container {
    background: #fff;
    position: relative;
    /* overflow-y: hidden; */
}

.tabs__list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    transition: all 0.3s linear;
}

.tabs__list-tab.active {
    opacity: 1;
    display: block !important;

}

.tabs__list-tab {
    width: 100%;
    min-width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    position: relative;
    transition: opacity 0.5s ease;
    opacity: 0;
    display: none !important;
}

.tab_content_wrapper p {
    color: var(--Gray-Scale-Gray-500, #808080);

    /* Body Small/Body Small, 400 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
}

.tab_content_list {
    list-style: none;
}

.tab_content_list li {
    color: var(--Gray-Scale-Gray-500, #808080);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    margin-bottom: 10px;
}

.newsItems {
    display: flex;
    margin-bottom: 30px;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px !important;
}

.newsItems .leftImg {
    width: 45%;
    margin-right: 20px;
}

.newsItems .leftImg img {
    width: 100% !important;
}
</style>
